import { lazy } from 'react'
import { Navigate, useRoutes } from 'react-router-dom'
import { retry } from '../common/utils/CommonFunctions'
import { useAuthContext } from '../hooks/useAuthContext'

const Sidebar = lazy(() => retry(() => import('../layout/Sidebar')))
const Login = lazy(() => retry(() => import('../pages/Login')))
const FourOhFourHome = lazy(() =>
  retry(() => import('../pages/FourOhFourHome')),
)
const AboutUs = lazy(() => retry(() => import('../pages/AboutUs')))
const PrivacyPolicy = lazy(() => retry(() => import('../pages/PrivacyPolicy')))
const Support = lazy(() => retry(() => import('../pages/Support')))
const TermsAndConditions = lazy(() =>
  retry(() => import('../pages/TermsAndConditions')),
)
const AboutUsFr = lazy(() => retry(() => import('../pages/AboutUsFr')))
const PrivacyPolicyFr = lazy(() =>
  retry(() => import('../pages/PrivacyPolicyFr')),
)
const SupportFr = lazy(() => retry(() => import('../pages/SupportFr')))
const TermsAndConditionsFr = lazy(() =>
  retry(() => import('../pages/TermsAndConditionsFr')),
)
const AboutUsAr = lazy(() => retry(() => import('../pages/AboutUsAr')))
const PrivacyPolicyAr = lazy(() =>
  retry(() => import('../pages/PrivacyPolicyAr')),
)
const SupportAr = lazy(() => retry(() => import('../pages/SupportAr')))
const TermsAndConditionsAr = lazy(() =>
  retry(() => import('../pages/TermsAndConditionsAr')),
)
const PageUnderConstruction = lazy(() =>
  retry(() => import('../pages/PageUnderConstruction')),
)
const Home = lazy(() => retry(() => import('../pages/Home')))
const DeleteAccount = lazy(() => retry(() => import('../pages/DeleteAccount')))

const Routes = () => {
  const { token } = useAuthContext()

  const routes = useRoutes([
    {
      path: '/',
      element: <Home />,
    },
    {
      path: '/en/about',
      element: <AboutUs />,
    },
    {
      path: '/en/privacy-policy',
      element: <PrivacyPolicy />,
    },
    {
      path: '/en/support',
      element: <Support />,
    },
    {
      path: '/en/terms-and-conditions',
      element: <TermsAndConditions />,
    },
    {
      path: '/fr/about',
      element: <AboutUsFr />,
    },
    {
      path: '/fr/privacy-policy',
      element: <PrivacyPolicyFr />,
    },
    {
      path: '/fr/support',
      element: <SupportFr />,
    },
    {
      path: '/fr/terms-and-conditions',
      element: <TermsAndConditionsFr />,
    },
    {
      path: '/ar/about',
      element: <AboutUsAr />,
    },
    {
      path: '/ar/privacy-policy',
      element: <PrivacyPolicyAr />,
    },
    {
      path: '/ar/support',
      element: <SupportAr />,
    },
    {
      path: '/ar/terms-and-conditions',
      element: <TermsAndConditionsAr />,
    },
    {
      path: '/delete-account',
      element: <DeleteAccount />,
    },
    {
      path: '/admin/login',
      element: !token ? <Login /> : <Navigate replace to="/admin/dashboard" />,
    },
    {
      path: '/admin',
      element: <Navigate replace to="/admin/login" />,
    },
    {
      path: '/admin/*',
      element: token ? <Sidebar /> : <Navigate replace to="/admin/login" />,
    },
    {
      path: '/*',
      element: <Navigate replace to="/404" />,
    },
    {
      path: '/404',
      element: <FourOhFourHome />,
    },
    {
      path: '/503',
      element: <PageUnderConstruction />,
    },
  ])

  return routes
}

export default Routes
